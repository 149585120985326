import React from 'react';
import { Link } from "gatsby";
import * as Icon from 'react-feather';
import { graphql, useStaticQuery } from "gatsby"

import MapImg from '../../assets/images/map.png'
import Shape1 from '../../assets/images/shape1.png'
import Shape2 from '../../assets/images/shape2.svg'

const query = graphql`
  	{   
        strapiSiteLogo {
            blackLogo {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            whiteLogo {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
         }
  	}
`

const Footer = () => {

    const data = useStaticQuery(query)
    const {strapiSiteLogo: {blackLogo}} = data

    const currentYear = new Date().getFullYear();

    return (
        <footer className="footer-area bg-f7fafd">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <div className="logo">
                                <Link to="/">
                                    <img
                                        src={blackLogo.childImageSharp.fluid.src}
                                        alt="Logo"
                                    />
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget pl-5">
                            <h3>Company</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/about">
                                        About Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget">
                            <h3>Support</h3>
                            <ul className="list">
                                <li>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-3 col-sm-6">
                        <div className="single-footer-widget contact-info">
                            <Icon.Mail />
                            <a href="mailto:enquires@doitcloud.co.uk">enquires@doitcloud.co.uk</a>

                        </div>
                    </div>

                    <div className="row justify-content-center single-footer-widget footer-address">
                            <h3>Address</h3>

                                <div className="col-lg-4 col-sm-6">
                                        <Icon.MapPin /> <br />
                                        VISTA HOUSE, 413 <br />
                                        BIRMINGHAM ROAD REDDITCH, <br />
                                        ENGLAND, B97 6RL
                                    <br />

                                </div>
                                <div className="col-lg-4 col-sm-6">

                                    <Icon.MapPin /> <br />
                                    Suite 5, Rajendar Place, <br /> Noida
                                    <br />

                                </div>
                                <div className="col-lg-4 col-sm-6">

                                    <Icon.MapPin /> <br />
                                    45 Clarke St, Melbourne, <br />
                                    Australia
                                      <br />


                                </div>
                                <br />

                            {/*<ul className="social-links">*/}
                            {/*    <li>*/}
                            {/*        <a href="https://www.facebook.com/" className="facebook" target="_blank" rel="noreferrer"><Icon.Facebook /></a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="https://twitter.com/" className="twitter" target="_blank" rel="noreferrer"><Icon.Twitter /></a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="https://www.instagram.com/" className="instagram" target="_blank" rel="noreferrer"><Icon.Instagram /></a>*/}
                            {/*    </li>*/}
                            {/*    <li>*/}
                            {/*        <a href="https://www.linkedin.com/" className="linkedin" target="_blank" rel="noreferrer"><Icon.Linkedin /></a>*/}
                            {/*    </li>*/}
                            {/*</ul>*/}
                    </div>

                    <div className="col-lg-12 col-md-12">
                        <div className="copyright-area">
                            <p>Copyright &copy; {currentYear} DOIT Cloud. </p>
                        </div>
                    </div>
                </div>
            </div>

            <img src={MapImg} className="map" alt="map" />

            {/* Shape Images */}
            <div className="shape1">
                <img src={Shape1} alt="shape" />
            </div>
            <div className="shape8 rotateme">
                <img src={Shape2} alt="shape" />
            </div>
        </footer>
    )

}

export default Footer;
