import React from "react";
import {Link} from 'gatsby';
import { graphql, useStaticQuery } from "gatsby"

const query = graphql`
  	{   
        strapiSiteLogo {
            blackLogo {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            whiteLogo {
                childImageSharp {
                    fluid {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
         }
  	}
`

const Navbar = () => {

    const data = useStaticQuery(query)
    const {strapiSiteLogo: {blackLogo}} = data

    const [menu, setMenu] = React.useState(true)

    const toggleNavbar = () => {
        setMenu(!menu)
    }

    React.useEffect(() => {
        let elementId = document.getElementById("header");
        document.addEventListener("scroll", () => {
            if (window.scrollY > 170) {
                elementId.classList.add("is-sticky");
            } else {
                elementId.classList.remove("is-sticky");
            }
        });
    })

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    return (
        <header id="header" className="headroom navbar-style-two">
            <div className="startp-nav">
                <div className="container">
                    <nav className="navbar navbar-expand-md navbar-light">
                        <a href="/" onClick={toggleNavbar} className="navbar-brand">
                            <img
                                src={blackLogo.childImageSharp.fluid.src}
                                alt="Logo"
                            />
                        </a>

                        <button
                            onClick={toggleNavbar}
                            className={classTwo}
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar top-bar"></span>
                            <span className="icon-bar middle-bar"></span>
                            <span className="icon-bar bottom-bar"></span>
                        </button>

                        <div className={classOne} id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto">
                                <li className="nav-item">
                                    <a href="/#main" activeClassName="active" onClick={toggleNavbar} className="nav-link">
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#products" onClick={toggleNavbar} className="nav-link">
                                        Services & Products
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#verticals" onClick={toggleNavbar} className="nav-link">
                                        Verticals
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="/#certifications" onClick={toggleNavbar} className="nav-link">
                                        Certifications
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <Link to="/about">
                                        About Us
                                    </Link>
                                </li>

                                <li className="nav-item">
                                    <Link to="/contact" activeClassName="active" className="nav-link">
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>

                    </nav>
                </div>
            </div>
        </header>
    );
}

export default Navbar;
